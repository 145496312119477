import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { CgGym } from "react-icons/cg";
import { GiCanoe } from "react-icons/gi";
import { IoIosFootball } from "react-icons/io";
import { MdOutlineModeOfTravel } from "react-icons/md";

function AboutCard() {
  const  [age, setAge] = useState(0);
  const  [experience, setExperience] = useState(5);
  useEffect(() => {
    const year = new Date().getFullYear();
    setAge(year - 1992);
    setExperience(year - 2015);
  },[age]);

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          Olá a todos, sou <span className="purple">Rogério Nascimento</span>, tenho {age} anos e sou <span className="purple">brasileiro</span>.
          <br />          
          Atualmente, vivo em Portugal e trabalho como desenvolvedor <span className="purple">back-end </span> 
          com foco em <span className="purple">PHP (Laravel)</span> e <span className="purple">Node.js</span> na Decskill desde outubro de 2022.
          <br />
          <br />
          Sou <span className="purple">bacharel</span> em <span className="purple">Sistemas de Informação</span> com
          mais de {experience} anos de experiência, tenho especializações 
          em <span className="purple">Node.js, React</span> e <span className="purple">React Native</span>.          
          Além disso, já trabalhei como desenvolvedor fullstack com <span className="purple">C# e Angular </span> 
          por mais de dois anos em uma das empresas pelas quais passei.          
            <br />
            <br />
            Além da <span className="purple">"codar"</span>, algumas outras atividades que eu adoro fazer!
          </p>
          <ul>
            <li className="about-activity">
              <CgGym /> Musculação
            </li>
            <li className="about-activity">
              <GiCanoe /> Canoagem
            </li>
            <li className="about-activity">
              <MdOutlineModeOfTravel /> Viagens
            </li>
            <li className="about-activity">
              <IoIosFootball /> Futebol
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Não compare a si mesmo com ninguém neste mundo. Se você fizer isso, estará insultando a si mesmo."{" "}
          </p>
          <footer className="blockquote-footer">Bill Gates</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
