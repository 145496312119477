import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Bacharel em Sistemas de Informação",
          "Software Developer",
          "PHP specialist",
          "NODE specialist",
          "React native specialist",          
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 40,
      }}
    />
  );
}

export default Type;
