import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiAngularSimple,
  DiGit,
  DiJavascript1,
  DiNodejs,
  DiReact
} from "react-icons/di";
import {
  SiCsharp,
  SiFirebase,
  SiLaravel,
  SiMicrosoftsqlserver,
  SiMysql,
  SiPhp,
  SiPostgresql,
  SiRedis,
  SiTypescript
} from "react-icons/si";
import { TbBrandReactNative } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiPhp />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>      
      <Col xs={4} md={2} className="tech-icons">
        <SiTypescript />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
      </Col>
      
      
      <Col xs={4} md={2} className="tech-icons">
        <SiLaravel/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>      
      <Col xs={4} md={2} className="tech-icons">
        <DiAngularSimple/>
      </Col> 
      <Col xs={4} md={2} className="tech-icons">
        <TbBrandReactNative/>
      </Col>                 
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftsqlserver />
      </Col>      
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
      </Col>                  
    </Row>
  );
}

export default Techstack;
